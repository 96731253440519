import React, { CSSProperties } from 'react'

import { computed } from 'mobx'

import moment from 'moment'

import { GeolocationPane, PaymentCardIcon } from '~/components'

import { Column } from '~/components/grid'

import CardType from '~/constants/card-systems'

import { P2PTransfer, P2PStatus } from '~/api/p2p'

import { formatMoneySum } from '~/utils'
import strict from '~/utils/strict'

import styles from '../styles.css'

import StatusIcon from '~/components/status-icon'

import { DetailedFieldsGroup } from '../../types'

import statusStyles from '../constants/p2p-statuses'

import res from '../res'
import { observer } from 'mobx-react'
import { values } from 'lodash'
import { render } from 'react-dom'

const renderCurrencyAmount = (
    value: number,
    payment: P2PTransfer,
    style?: CSSProperties
) => {
    return (
        <span style={style}>
            <span className={styles.amountValue}>{formatMoneySum(value)}</span>
            <span className={styles.amountCurrency}>{payment.currency}</span>
        </span>
    )
}

const renderDate = (value, hideHours?: boolean) =>
    value ? (
        <span className={styles.date}>
            <span>{moment(value).format('DD.MM.YYYY')}</span>
            {!hideHours && (
                <span className="date-hours">
                    {moment(value).format('hh:mm')}
                </span>
            )}
        </span>
    ) : null

const renderGeolocationText = (payment: P2PTransfer) => {
    const {
        ipCity,
        ipCountry,
        ipRegion,
        ipDistrict,
        ipLatitude,
        ipLongitude
    } = payment

    let locationSpecification = [ipCountry, ipRegion, ipDistrict]
        .filter(item => !!item)
        .join(', ')

    if (locationSpecification.length) {
        locationSpecification = '(' + locationSpecification + ')'
    }

    return (
        <div>
            <div>
                {ipCity} {locationSpecification}
            </div>
            <a
                href={`http://www.google.com/maps/place/${ipLatitude},${ipLongitude}`}
            >{`N${ipLatitude}, E${ipLongitude}`}</a>
        </div>
    )
}

const renderGeolocationMap = (payment: P2PTransfer) => {
    const { ipLatitude, ipLongitude } = payment

    return (
        <div className={styles.geolocationContainer}>
            <GeolocationPane
                center={{
                    lat: ipLatitude,
                    lng: ipLongitude
                }}
            />
        </div>
    )
}

const fields = computed(() => {
    const { short: titles, full: tooltips } = res().dataFields
    return strict<Array<Column<P2PTransfer>>>([
        /*
        {
            field: 'shop',
            title: titles.shop,
            titleTooltip: tooltips.shop
        },
        */
        {
            field: 'invoiceID',
            title: titles.invoiceID,
            titleTooltip: tooltips.invoiceID,
            render: value => <span className={styles.orderNumber}>{value}</span>
        },
        {
            field: 'dateTime',
            title: titles.dateTime,
            titleTooltip: tooltips.dateTime,
            render: value => renderDate(value)
        },
        /*
            {
                field: 'orgAmount',
                title: titles.orgAmount,
                titleTooltip: tooltips.orgAmount,
                render: (value, payment) => (
                    <span>
                        {formatMoneySum(value)} {payment.currency}
                    </span>
                )
            },
        */
        {
            field: 'amount',
            title: titles.amount,
            titleTooltip: tooltips.amount,
            className: 'rightAlign',
            render: renderCurrencyAmountLabel
        },
        {
            field: 'fee',
            title: titles.fee,
            titleTooltip: tooltips.fee,
            className: 'rightAlign',
            render: (value, payment) => renderCurrencyAmount(value, payment)
        },
        {
            field: 'code',
            title: titles.code,
            titleTooltip: tooltips.code,
            render: value => renderStatusLabel(value, true),
            showTooltip: false
        },
        {
            field: 'reason',
            title: titles.reason,
            titleTooltip: tooltips.reason
            // render: value => renderStatusLabel(value, false)
        },

        // TODO: Bring it back once the transaction types implemented
        // {
        //     field: 'transactionTypes',
        //     title: titles.transactionTypes,
        //     titleTooltip: tooltips.transactionTypes,
        //     render: (value, payment) => renderTransactionTypes(payment)
        // },
        // {
        //     field: 'installment_id',
        //     title: titles.installment_id,
        //     titleTooltip: tooltips.installment_id
        // },
        {
            field: 'reference',
            title: titles.reference,
            titleTooltip: tooltips.reference
        },
        // {
        //     field: 'senderTransferType',
        //     title: titles.senderTransferType,
        //     titleTooltip: tooltips.senderTransferType
        // },
        // {
        //     field: 'receiverTransferType',
        //     title: titles.receiverTransferType,
        //     titleTooltip: tooltips.receiverTransferType
        // },
        {
            field: 'senderCardMask',
            title: titles.senderCardMask,
            titleTooltip: tooltips.senderCardMask,
            render: (value, payment) => renderCardMaskAndType(value, payment)
        },
        {
            field: 'receiverCardMask',
            title: titles.receiverCardMask,
            titleTooltip: tooltips.receiverCardMask,
            render: (value, payment) => renderCardMaskAndTypeReceiver(value, payment)
        },
        {
            field: 'name',
            title: titles.name,
            titleTooltip: tooltips.name
        },
        {
            field: 'phone',
            title: titles.phone,
            titleTooltip: tooltips.phone
        },
        {
            field: 'accountID',
            title: titles.accountID,
            titleTooltip: tooltips.accountID
        },
        {
            field: 'email',
            title: titles.email,
            titleTooltip: tooltips.email
        },
        {
            field: 'description',
            title: titles.description,
            titleTooltip: tooltips.description
        }
        /*
        {
            field: 'postLink',
            title: titles.postLink,
            titleTooltip: tooltips.postLink
        },
        */
        /*
        {
            field: 'amountBonus',
            title: titles.amountBonus,
            titleTooltip: tooltips.amountBonus
        },
        */
        /*
         {
             field: 'payoutDate',
             title: titles.payoutDate,
             titleTooltip: tooltips.payoutDate,
             render: value => renderDate(value)
         },
         */
        /*
        {
            field: 'payoutAmount',
            title: titles.payoutAmount,
            titleTooltip: tooltips.payoutAmount,
            className: 'rightAlign',
            render: (value, payment) => renderCurrencyAmount(value, payment)
        }
        */
    ])
})

const detailedFields = computed(() => {
    const {
        groups: groupsTitles,
        short: titles,
        geolocation: geolocationTitle
    } = res().dataFields

    return strict<Array<DetailedFieldsGroup<P2PTransfer>>>([
        {
            id: 'main',
            subgroups: [
                [
                    {
                        field: 'code',
                        title: titles.code,
                        render: value => {
                            const title = res().paymentStatuses[value]
                            return value ? (
                                <>
                                    <StatusIcon
                                        title={title}
                                        color={
                                            (statusStyles[value] || {}).color
                                        }
                                    />
                                    <span className={styles.statusLabel}>
                                        {title}
                                    </span>
                                </>
                            ) : null
                        }
                    },
                    {
                        field: 'reason',
                        title: titles.reason
                    },
                    /*
                    {
                        field: 'createdAt',
                        title: titles.createdAt,
                        render: value => renderDate(value)
                    },
                    */
                    /*
                    {
                        field: 'shop',
                        title: titles.shop
                    },
                    */
                    {
                        field: 'invoiceID',
                        title: titles.invoiceID
                    },
                    /*
                    {
                        field: 'client',
                        title: titles.client
                    },
                    */
                    {
                        field: 'description',
                        title: titles.description
                    }
                ],
                [
                    /*
                        {
                            field: 'orgAmount',
                            title: titles.orgAmount,
                            render: (value, payment) => (
                                <span>
                                    {formatMoneySum(value)} {payment.currency}
                                </span>
                            )
                        },
                    */
                    {
                        field: 'amount',
                        title: titles.amount,
                        render: (value, payment) => {
                            const statusStyle = statusStyles[payment.code]
                            return renderCurrencyAmount(
                                value,
                                payment,
                                statusStyle
                                    ? { color: statusStyle.color }
                                    : null
                            )
                        }
                    },
                    {
                        field: 'fee',
                        title: titles.fee,
                        render: (value, payment) =>
                            renderCurrencyAmount(value, payment)
                    }
                    /*
                    {
                        field: 'amountBonus',
                        title: titles.amountBonus,
                        render: value => formatMoneySum(value)
                    }
                    */
                ],

                [
                    /*
                    {
                        field: 'installment_id',
                        title: titles.installment_id
                    },
                    */
                    // {
                    //     field: 'senderTransferType',
                    //     title: titles.senderTransferType
                    // },
                    // {
                    //     field: 'receiverTransferType',
                    //     title: titles.receiverTransferType
                    // }
                ],

                [

                    {
                        field: 'dateTime',
                        title: titles.dateTime,
                        render: value => renderDate(value, true)
                    },
                    {
                        field: 'senderIssuer',
                        title: titles.senderIssuer
                    },
                    // {
                    //     field: 'senderCardID',
                    //     title: titles.senderCardID
                    // },
                    // {
                    //     field: 'senderOwID',
                    //     title: titles.senderOwID
                    // },
                    {
                        field: 'senderCardMask',
                        title: titles.senderCardMask,
                        render: (value, payment) =>
                            !value ? (
                                undefined
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}
                                >
                                    <PaymentCardIcon
                                        type={payment.senderCardType as CardType}
                                        className={styles.creditCardIcon}
                                    />
                                    <span className={styles.creditCardMask}>
                                        {value}
                                    </span>
                                </div>
                            )
                    },

                    {
                        field: 'receiverIssuer',
                        title: titles.receiverIssuer
                    },
                    // {
                    //     field: 'receiverCardID',
                    //     title: titles.receiverCardID
                    // },
                    // {
                    //     field: 'receiverOwID',
                    //     title: titles.receiverOwID
                    // },
                    {
                        field: 'receiverCardMask',
                        title: titles.receiverCardMask,
                        render: (value, payment) =>
                            !value ? (
                                undefined
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}
                                >
                                    <PaymentCardIcon
                                        type={payment.receiverCardType as CardType}
                                        className={styles.creditCardIcon}
                                    />
                                    <span className={styles.creditCardMask}>
                                        {value}
                                    </span>
                                </div>
                            )
                    },
                    {
                        field: 'secure',
                        title: titles.secure,
                        render: value =>
                            value === 'true'
                                ? res().secureValues.true
                                : res().secureValues.false
                    }
                    /*
                    {
                        field: 'merchant',
                        title: titles.merchant
                    }
                    */
                ],

                [
                    {
                        field: 'name',
                        title: titles.name
                    },
                    {
                        field: 'email',
                        title: titles.email
                    },
                    {
                        field: 'phone',
                        title: titles.phone
                    }
                    /*
                    {
                        field: 'chargeDate',
                        title: titles.chargeDate,
                        render: value => renderDate(value),
                        visibility: (value, payment) =>
                            payment.status === P2PStatus.charged
                    },
                    */
                    /*
                    {
                        field: 'payoutDate',
                        title: titles.payoutDate,
                        visibility: (value, payment) =>
                            payment.status === P2PStatus.refunded
                    },
                    */
                    /*
                    {
                        field: 'payoutAmount',
                        title: titles.payoutAmount,
                        visibility: (value, payment) =>
                            payment.status === P2PStatus.refunded
                    }
                    */
                ]
            ]
        },
        {
            id: 'customerData',
            title: groupsTitles.location,
            subgroups: [
                [
                    {
                        field: 'ip',
                        title: titles.ip
                    },
                    {
                        field: 'geolocationText',
                        title: geolocationTitle,
                        render: (value, payment) =>
                            renderGeolocationText(payment),
                        withoutBorder: true
                    },
                    {
                        field: 'geolocationMap',
                        title: null,
                        render: (value, payment) =>
                            renderGeolocationMap(payment),
                        wide: true,
                        withoutBorder: true
                    }
                ]
            ]
        },
        {
            id: 'paymentPage',
            title: groupsTitles.paymentPage,
            subgroups: [
                [
                    {
                        field: 'language',
                        title: titles.language
                    }
                    /*
                    {
                        field: 'template',
                        title: titles.template
                    },
                    */
                    /*
                    {
                        field: 'postLink',
                        title: titles.postLink
                    },
                    */

                    // {
                    //     field: 'postLinkStatus',
                    //     title: titles.postLinkStatus,
                    //     render: value =>
                    //         value === undefined ? (
                    //             undefined
                    //         ) : value ? (
                    //             <span
                    //                 className={`${styles.postlinkStatus} ${styles.ok}`}
                    //             >
                    //                 {res().postLinkStatus.ok}
                    //             </span>
                    //         ) : (
                    //             <span
                    //                 className={`${styles.postlinkStatus} ${styles.failed}`}
                    //             >
                    //                 {res().postLinkStatus.fail}
                    //             </span>
                    //         )
                    // },

                    /*
                    {
                        field: 'client',
                        title: titles.client
                    }
                    */
                ]
            ]
        }
    ])
})

export function renderCurrencyAmountLabel(value, payment: P2PTransfer) {
    const statusStyle = statusStyles[payment.code]
    return (
        <>
            {renderCurrencyAmount(
                value,
                payment,
                statusStyle ? { color: statusStyle.color } : null
            )}
            {/*payment.amountBonus !== 0 && (
                <>
                    <span className={styles.amountValue}>
                        {formatMoneySum(payment.amountBonus)}
                    </span>
                    <span className={styles.amountCurrency}>Go! Bonus</span>
                </>
            )*/}
        </>
    )
}
export function renderStatusLabel(value, renderText: boolean = false) {
    return value ? (
        <React.Fragment>
            <StatusIcon
                title={res().paymentStatuses[value]}
                color={(statusStyles[value] || {}).color}
            />
            &nbsp;
            {renderText && <span>{res().paymentStatuses[value]}</span>}
        </React.Fragment>
    ) : null
}

// determines how to render the card mask, card type, and weather the bonus tag is visible or not

function implyCardType(cardMask: string): JSX.Element {
    switch (cardMask[0]) {
        case '4':
            return (
                <PaymentCardIcon
                    type={'VISA' as CardType}
                    className={styles.creditCardIcon}
                />
            )
        case '5':
            return (
                <PaymentCardIcon
                    type="MasterCard"
                    className={styles.creditCardIcon}
                />
            )
        case '3':
            return (
                <PaymentCardIcon
                    type="AmericanExpress"
                    className={styles.creditCardIcon}
                />
            )
        default:
            return <></>
    }
}

function renderCardMaskAndType(value: any, payment: P2PTransfer): JSX.Element {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {/*payment.amountBonus > 0 && (
                <div
                    style={{ marginRight: '10px' }}
                    className={styles.iconDescriptionWrapper}
                >
                    <PaymentCardIcon
                        type="goBonus"
                        className={styles.creditCardIcon}
                    />
                    <span style={{ paddingLeft: '5px' }}>Go! Bonus</span>
                </div>
            )*/}
            <div className={styles.iconDescriptionWrapper}>
                {/*TODO: Temporary solution change this whole logic once the solution is implemented on the back end  */}
                {payment.senderCardType !== '' ? (
                    <PaymentCardIcon
                        type={payment.senderCardType as CardType}
                        className={styles.creditCardIcon}
                    />
                ) : (
                    implyCardType(payment.senderCardMask)
                )}

                {value ? (
                    <span className={styles.creditCardMask}>{value}</span>
                ) : (
                    undefined
                )}
            </div>
        </div>
    )
}

function renderCardMaskAndTypeReceiver(value: any, payment: P2PTransfer): JSX.Element {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {/*payment.amountBonus > 0 && (
                <div
                    style={{ marginRight: '10px' }}
                    className={styles.iconDescriptionWrapper}
                >
                    <PaymentCardIcon
                        type="goBonus"
                        className={styles.creditCardIcon}
                    />
                    <span style={{ paddingLeft: '5px' }}>Go! Bonus</span>
                </div>
            )*/}
            <div className={styles.iconDescriptionWrapper}>
                {/*TODO: Temporary solution change this whole logic once the solution is implemented on the back end  */}
                {payment.receiverCardType !== '' ? (
                    <PaymentCardIcon
                        type={payment.receiverCardType as CardType}
                        className={styles.creditCardIcon}
                    />
                ) : (
                    implyCardType(payment.receiverCardType)
                )}

                {value ? (
                    <span className={styles.creditCardMask}>{value}</span>
                ) : (
                    undefined
                )}
            </div>
        </div>
    )
}

// takes a transaction and determines which transaction type logos must be rendered
export function renderTransactionTypes(transaction: P2PTransfer) {
    const applePayIcon = (
        <svg height={15} width={15}>
            <circle cx={7.5} cy={7.5} r={7} fill="black" />
        </svg>
    )

    const samsungPayIcon = (
        <svg height={15} width={15}>
            <circle cx={7.5} cy={7.5} r={7} fill="blue" />
        </svg>
    )

    const goBonusIcon = (
        <svg height={15} width={15}>
            <circle cx={7.5} cy={7.5} r={7} fill="green" />
        </svg>
    )

    const invoiceLinkIcon = (
        <svg height={15} width={15}>
            <circle cx={7.5} cy={7.5} r={7} fill="yellow" />
        </svg>
    )
    return (
        <>
            {/*transaction.amountBonus > 0 && goBonusIcon*/}
            {transaction.senderCardType === 'ApplePay' && applePayIcon}
            {transaction.senderCardType === 'SamsungPay' && samsungPayIcon}

            {transaction.receiverCardType === 'ApplePay' && applePayIcon}
            {transaction.receiverCardType === 'SamsungPay' && samsungPayIcon}
        </>
    )
}

export { detailedFields }

export default fields
