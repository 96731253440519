import res from './res'

res.add('en', {
    dataFields: {
        titles: {
            createdDate: 'Created date',
            endDate: 'Expiration date',
            order: 'Order',
            sum: 'Sum',
            language: 'Alert language',
            installment: 'Installment period',
            status: 'Status',
            merchantId: 'Merchant ID',
            description: 'Description',
            emailToSend: 'Email to send',
            phoneToSend: 'Phone to send',
            notificationEmail: 'Notification email',
            notificationPhone: 'Notification phone'
        }
    },
    sortOptions: {
        createdDate: 'Date of creation',
        expirationDate: 'Date of expiration',
        order: 'Order',
        asc: {
            createdDate: 'old first',
            expirationDate: 'ascending',
            order: 'ascending'
        },
        desc: {
            createdDate: 'new first',
            expirationDate: 'descending',
            order: 'descending'
        }
    },
    postLinkStatuses: {
        ACTIVE: 'Active',
        ANNULLED: 'Cancelled',
        EXPIRED: 'Expired',
        CHARGED: 'Charged'
    },
    language: 'Language',
    languages: {
        eng: 'English',
        rus: 'Russian',
        kaz: 'Kazakh'
    },
    tooltips: {
        copyLink: 'Copy link',
        cancelLink: 'Cancel link '
    },
    sortBy: 'Sort by',
    installmentLabel: (val: string) => {
        const value = parseInt(val, null)

        if (!value || value === 0) return 'Without installment'

        return `${value} month${value === 1 ? '' : 's'}`
    },
    inactiveLinkWarningTitle: 'Warning',
    inactiveLinkWarning:
        'This link has been cancelled, charged, or has expired.'
})
