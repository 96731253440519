import {
    action, computed,
    observable, reaction,
    runInAction, toJS
} from 'mobx'
import {
    apiCheck, checkByBin, createOrGetMerchant, getMerchantData, updateMerchantData, sendToComundaService,
    MerchantDataAPI, Contact, updateStep, uploadFile, getUploadedFiles, removeFile,
    downloadFile, getBankBranches, BankBranch, RegisterMerchant
} from '~/api'
import res from './res'
import FieldStore, { isValidArr } from '~/utils/field-store'
import { required } from '~/utils/validation'
import { noThrow } from '~/utils/control-flow'
import { notification } from '~/components'
import type { UploadFile } from 'antd/es/upload/interface'
import remove from 'lodash/remove'
import BrowserStore from '~/services/browser/browser-state'
import React from 'react'

export default class CreateOrderStore {

    @computed
    public get firstStepPercent() {
        let percent = 0
        percent = this.bin.value ? percent + 15 : percent
        percent = this.name.value ? percent + 15 : percent
        percent = this.surname.value ? percent + 15 : percent
        percent = this.phone.value ? percent + 15 : percent
        percent = this.email.value ? percent + 15 : percent
        percent = this.cityService ? percent + 15 : percent

        if (this.serviceBank === true || this.serviceBank === false) {
            percent = percent + 10
        }

        return percent
    }

    @computed
    public get secondStepPercent() {
        let percent = 0
        percent = this.url.value ? percent + 20 : percent
        percent = this.shopName.value ? percent + 20 : percent
        percent = this.existSixMonths ? percent + 20 : percent
        percent = this.serviceDescription ? percent + 20 : percent
        percent = this.tariff.value ? percent + 20 : percent
        return percent
    }

    constructor() {
        this.loadData()
    }

    public name = new FieldStore<string>(
        [required(() => res().messages.required)]
    )

    public surname = new FieldStore<string>(
        [required(() => res().messages.required)]
    )

    public middleName = new FieldStore<string>(
        [required(() => res().messages.required)]
    )

    public phone = new FieldStore<string>(
        [required(() => res().messages.required)]
    )

    public email = new FieldStore<string>(
        [required(() => res().messages.required)]
    )

    public bin = new FieldStore<string>(
        [required(() => res().messages.required)]
    )

    public url = new FieldStore<string>(
        [required(() => res().messages.required)]
    )

    public shopName = new FieldStore<string>(
        [required(() => res().messages.required)]
    )

    public tariff = new FieldStore<string>(
        [required(() => res().messages.required)]
    )

    @observable
    public serviceDescription: string

    @observable
    public contacts: Contact[] = []

    public cities: BankBranch[] = []

    @observable
    public tariffs: []

    @observable
    public files: UploadFile[] = []

    @observable
    public cityService: string

    @observable
    public currentMerchant: RegisterMerchant

    @observable
    public merchantData: any

    @observable
    public isModalOpen: boolean

    @observable
    public serviceBank: boolean

    @observable
    public existSixMonths: boolean

    @observable
    public isModified: boolean

    @observable
    public step: number

    @observable
    public orderStatusName: string

    @observable
    public firstProgress: number

    @observable
    public secondProgress: number

    @observable
    public thirdProgress: number

    @observable
    public govData: any

    @observable
    public companyForm: any

    @observable
    public isLoading: boolean

    @observable
    public btnRef: any = React.createRef()

    public async submit(e, form) {
        form.validateFields()
        e.preventDefault()
        return
    }

    @action.bound
    public async checkByBin(form) {

        if (!this.bin.value) {
            form.validateFields(['bin'])
            return
        }

        await this.updateData()

        const govResult = await noThrow(checkByBin(this.bin.value))

        if (govResult.error) {
            notification.error({
                message: 'Временно не работает проверка',
                description: 'Продолжайте заполнять поля'
            })
            runInAction(() => {
                this.isModalOpen = true
            })
            return
        }

        const govData = govResult.value.obj

        runInAction(() => {
            this.merchantData.bin = this.bin.value
            this.merchantData.companyName = govData.name.replace(/['"]+/g, '')
            this.merchantData.fio = govData.fio
            this.merchantData.okedName = govData.okedName
            this.merchantData.registerDate = govData.registerDate
            this.merchantData.katoAddress = govData.katoAddress
        })

        this.getMerchantForm()
        await this.updateData()
    }

    public async uploadFile(file) {

        const data = new FormData()

        data.append('file', file)

        const response = await uploadFile(data, this.currentMerchant.id)

        if (response.ok) {
            const newFile = await response.json()

            runInAction(() => {

                this.files = this.files.concat({
                    uid: newFile.id,
                    name: newFile.name,
                    status: 'done',
                    size: newFile.size,
                    type: 'text',
                    url: ''
                })
            })
        } else {
            notification.error({
                message: 'Error'
            })
        }
    }

    @action.bound
    public async removeFile(uid) {

        try {
            await apiCheck(removeFile(uid))

            runInAction(() => {
                this.files = remove(this.files, itm => itm.uid !== uid)
            })

        } catch (error) {
            notification.error({
                message: error.message
            })
        }
    }

    @action.bound
    public async loadUploadedFiles() {

        const { value } = await noThrow(getUploadedFiles(this.currentMerchant.id))
        runInAction(() => {
            this.files = value
        })
    }

    @action.bound
    public async setDescription(value: string) {
        this.serviceDescription = value
    }

    @action.bound
    public async onCityChange(city) {
        this.cityService = city
        await this.updateData()
    }

    @action.bound
    public async onChangeTariff(val) {
        this.tariff.set(val)
        await this.updateData()
    }

    @action.bound
    public async onChangeService(isHalyk) {
        this.serviceBank = isHalyk.target.value
        await this.updateData()
    }

    @action.bound
    public async onChangeCompanyForm(e) {
        this.companyForm = e.target.value
    }

    @action.bound
    public async onChangeEstablishmentDate(overSixMonths) {
        this.existSixMonths = overSixMonths.target.value
        await this.updateData()
    }

    @action.bound
    public async setBinChecked() {
        toJS(this.btnRef).current.buttonNode.classList.add('src-pages-orders-create-order-styles_alert')
        setTimeout(() => {
            toJS(this.btnRef).current.buttonNode.classList.remove('src-pages-orders-create-order-styles_alert')
        }, 2000)
    }

    @action.bound
    public async changeStep(step) {
        if (this.step === 1) {
            if (!this.companyForm) {
                this.setBinChecked()
                return
            }
            if (!isValidArr(this.bin, this.name, this.surname, this.phone, this.email)) {
                notification.warning({
                    message: res().messages.warningRequires
                })
                return
            }
        }

        if (this.step === 2 && step !== 1) {
            if (!isValidArr(this.url, this.shopName)) {
                return
            }
        }

        this.step = step
        await noThrow(updateStep(this.step))
    }

    @action.bound
    public async backStep() {
        this.step--
        await noThrow(updateStep(this.step))
    }

    @action.bound
    public async download(selectedFile) {

        const { value: response, error } = await noThrow(
            apiCheck(downloadFile(selectedFile.uid))
        )

        const file = selectedFile.name

        if (BrowserStore.isIE9) {
            const frame = document.createElement('iframe')
            document.body.appendChild(frame)

            frame.contentWindow.document.open('text/html', 'replace')
            frame.contentWindow.document.write('sep=,\r\n' + response.text())
            frame.contentWindow.document.close()
            frame.contentWindow.focus()
            frame.contentWindow.document.execCommand('SaveAs', true, file)

            document.body.removeChild(frame)
        } else {
            response.blob().then(b => {
                const a = document.createElement('a')
                const href = URL.createObjectURL(b)
                a.setAttribute('download', file)
                a.setAttribute('href', href)
                a.click()
            })
        }
    }

    @action.bound
    public async updateData() {

        const merchantData: MerchantDataAPI = {
            bin: this.bin.value,
            companyName: this.merchantData.companyName,
            fio: this.merchantData.fio,
            okedName: this.merchantData.okedName,
            registrationDate: this.merchantData.registerDate,
            katoAddress: this.merchantData.katoAddress,
            store: {
                existSixMonths: this.existSixMonths,
                url: this.url.value,
                name: this.shopName.value,
                serviceDescription: this.serviceDescription,
                tariff: this.tariff.value
            },
            staff: {
                surname: this.surname.value,
                name: this.name.value,
                middleName: this.middleName.value,
                phone: this.phone.value,
                email: this.email.value
            },
            cityService: this.cityService,
            serviceBank: { halyk: this.serviceBank },
            companyForm: this.companyForm
        }

        const result = await noThrow(updateMerchantData(merchantData, this.currentMerchant.id))
        return false
    }

    @action.bound
    public async finish() {

        runInAction(() => {
            this.isLoading = true
        })

        try {
            await noThrow(sendToComundaService())

            runInAction(() => {
                this.isLoading = false
                this.orderStatusName = 'SENT'
            })

        } catch (error) {

            this.isLoading = false

            notification.error({
                message: error.message
            })
        }
    }

    @action.bound
    public async setCompanyForm() {
        runInAction(() => {
            this.companyForm = this.companyForm ? this.companyForm : 'ip'
            this.isModalOpen = false
        })
    }

    @action.bound
    public async changeStatus() {
        this.orderStatusName = 'INPROGRESS'
    }

    @action.bound
    private onChange() {
        this.updateData()
    }

    @action.bound
    private async loadData() {

        const registerMerchant = await noThrow(apiCheck(createOrGetMerchant()))

        const bankBranches = await noThrow(apiCheck(getBankBranches()))

        runInAction(() => {
            this.cities = bankBranches.value
            this.currentMerchant = registerMerchant.value
        })

        const merchantData = await noThrow(apiCheck(getMerchantData(this.currentMerchant.id)))

        runInAction(() => {
            this.merchantData = merchantData.value
            this.serviceBank = this.merchantData.serviceBank.halyk
            this.step = this.currentMerchant.registrationStep
            this.orderStatusName = this.currentMerchant.statusName
            this.cityService = this.merchantData.cityService
            this.bin.value = this.merchantData.bin
            this.existSixMonths = this.merchantData.store.existSixMonths
            this.shopName.value = this.merchantData.store.name
            this.url.value = this.merchantData.store.url
            this.serviceDescription = this.merchantData.store.serviceDescription
            this.tariff.value = this.merchantData.store.tariff
            this.name.value = this.merchantData.staff.name
            this.surname.value = this.merchantData.staff.surname
            this.middleName.value = this.merchantData.staff.middleName
            this.phone.value = this.merchantData.staff.phone
            this.email.value = this.merchantData.staff.email
            this.companyForm = this.merchantData.companyForm
        }
        )
        this.loadUploadedFiles()
    }

    private getMerchantForm() {

        if (this.companyForm) {
            return
        }

        const str = this.merchantData.companyName.split(' ')
        runInAction(() => {

            const formByTitle = str.length && str[0]
            if (formByTitle === 'ТОО') {
                this.companyForm = 'too'
            }

            if (formByTitle === 'ИП') {
                this.companyForm = 'ip'
            }

            if (formByTitle !== 'ТОО' && formByTitle !== 'ИП') {
                this.isModalOpen = true
            }
        })
    }
}
