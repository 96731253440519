import { RangePopoverValue } from '~/components/range-popover'
import { StatementFormats } from '~/pages/statements/constants'
import { StatementType } from '~/pages/statements/constants'
import { ListResponse } from '../contracts'

export type StatementsResponse = ListResponse<StatementResponse>

export interface Scheduler {
    schedulerId?: string
    frequency: FrequencyType
    emails: string[]
    format: string
    enable: boolean
}

export interface StatementResponse {
    shopId: string
    name: string
    type: string
    autoStatements: boolean
    schedulers: Scheduler[]
    terminals: string[]
    contract: string
    lastStatement: Date
}

export interface UpdateStatementRequest {
    id: string
    emails: string[]
    format: string
    enable: boolean
    procedure: string
}

export interface CreateStatementRequest {
    name: string
    type: string
    frequency: string
    emails: string[]
    format: string
    fileFormat: string
}

export interface ChangeStatementResponse {
    id: string
    name: string
    type: string
    frequency: string
    emails: string[]
    format: string,
    enable: boolean
}

export interface ContractStatementSchedule {
    format?: string
    emails?: string[]
    enable: boolean
    schedulerId?: string
    fileFormat?: string
    procedure?: string
}

export interface ManualStatement {
    range?: RangePopoverValue
    emails: string[]
    shopID: string
    format: string
    fileFormat?: string
    procedure?: string
}

export enum FrequencyType {
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    custom = 'custom'
}

export enum InitiationType {
    custom,
    auto
}

export enum HistoryItemStatus {
    success = 'SUCCESS',
    inProgress = 'INPROGRES',
    error = 'FAILED',
    noData = 'NO_DATA'
}

export interface ContractStatementInfo {
    shopId: string
    name: string
    contract: string
    type: string
    daily?: ContractStatementSchedule
    weekly?: ContractStatementSchedule
    monthly?: ContractStatementSchedule
    terminals?: string[]
}

export interface StatementHistoryResponse {
    id: string
    create: Date
    status: HistoryItemStatus
    frequency: FrequencyType
    contract: string
    initiator: string
    fromDate: Date
    toDate: Date
    emails: string[] | string
    format: string
    shedulerId?: string
    fileFormat?: string
}

export interface ContractStatementHistory {
    id: string
    create: Date
    period: Date[]
    emails: string[]
    initiationType: FrequencyType
    format: string
    status: HistoryItemStatus
    initiator?: string
    fileFormat?: string
}

export interface GenerateStatementRequest {
    fromDate: Date,
    toDate: Date,
    shopID: string,
    contract: string,
    format: StatementFormats,
    fileFormat?: string,
    procedure: StatementType
}
