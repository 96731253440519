export default {
    api: {
        baseUrl: 'https://testepay.homebank.kz/api/'
    },
    auth: {
        baseUrl: 'https://testoauth.homebank.kz/epay2/',
        client: {
            scope: 'webapi usermanagement email_send verification statement statistics',
            id: 'web',
            secret: 'h$PvhiWrLn*d)B5I',
            storageKey: 'appAuth'
        },
        user: {
            scope: 'webapi usermanagement email_send verification statement statistics',
            storageKey: 'auth',
            userInfoKey: 'userInfo'
        },
        idleTimeout: 10
    },
    settings: {
        ui: {
            siderVisibility: 'siderVisibility'
        }
    }
}
