import { ReactNode } from 'react'
import locale from '~/utils/locale'

export default locale.create<{
    title: string
    generalData: string
    chooseStoreLabel: string
    invoicePlaceholder: string
    accountIdPlaceholder: string
    paymentNotificationData: string
    fieldErrorMessage: string
    invalidAccountId: string
    invalidShop: string
    invalidOrderId: string
    invalidAmount: string
    descriptionRequired: string
    descriptionMaxLength: string
    invalidEmail: string,
    invalidPhone: string
    dataToSendLink: string
    generateButton: string
    applyButton: string
    cancelButton: string
    closeButton: string
    months: string
    notRequired: string
    withoutInstallment: string
    installmentTooltip: string
    formItemTitles: {
        orderNumber: string
        orderSum: string
        accountId: string
        language: string
        recieverEmail: string
        recieverPhone: string
        senderEmail: string
        senderPhone: string
        email: string
        phone: string
        description: string
        expirePeriod: string
    }
    confirmationTitle: (orderNumber: string) => ReactNode
    clipBoardMessage: string
    sumError: string
    duplicateOrderIdError: string
    serverError: string
    noShopsError: string
}>()
