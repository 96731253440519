import 'moment/locale/ru'

import antd from 'antd/lib/locale-provider/ru_RU'

import res from './res'

res.add('ru', {
    unknownError: 'Сейчас мы не можем обработать ваш запрос. Пожалуйста, повторите его позже',
    errorCodes: {
        75: 'Пользователь с таким email уже существует',
        506: 'Оформить возврат возможно на следующий день после проведения транзакции.',
        3141: 'Возврат невозможен ввиду отсутствия средств на контракте, пополните контракт или дождитесь возмещения по новым платежам.'
    },
    permissionError: 'У вас нет доступа к данной операции. Пожалуйста, обратитесь к администратору.',
    required: 'Обязательное поле',
    invalidEmail: 'Неправильный формат email адреса',
    invalidNumber: 'Введенное значение не является числом',
    success: 'Выполнено',
    info: 'Информация',
    warn: 'Предупреждение',
    error: 'Ошибка',
    valueFrom: 'от',
    valueTo: 'до',
    techinalInfo: 'Техническая информация',
    errorCode: 'Код ошибки',
    close: 'Закрыть',
    paymentsSystems: {
        visa: 'Visa',
        masterCard: 'Mastercard',
        amexCard: 'American Express',
        other: 'Другие карты'
    },
    longNumberDigits: {
        thousand: 'k',
        million: 'M',
        billion: 'G',
        trillion: 'T',
        quadrillion: 'P',
        quintillion: 'E'
    },
    antd,
    failedToFetch: 'Проблема с сетевым подключением или запрос был заблокирован брандмауэром или другой мерой безопасности'
})
