import { Moment } from 'moment'

import { ListRequest, ListResponse } from '~/api/contracts'

export type EpayFilterableField =
    | 'id'
    | 'created_date'
    | 'shop_id'
    | 'invoice_id'
    | 'account_id'
    | 'payer_phone'
    | 'payer_email'
    | 'post_link'
    | 'amount'
    | 'card_mask'
    | 'client'
    | 'installment_id'
    | 'payout_date'
    | 'status_id'
    | 'result_code'

export type EpaySortableField =
    | 'id'
    | 'created_date'
    | 'status_id'
    | 'post_link'

export type EpayPaymentRequest = ListRequest<
    EpayFilterableField,
    EpaySortableField
>

export type EpayPaymentResponse = ListResponse<EpayPayment>

export interface EpaySummaryRequest {
    from?: Moment
    to?: Moment
}

export enum PaymentStatus {
    auth = 'AUTH',
    charged = 'CHARGE',
    cancelled = 'CANCEL',
    refunded = 'REFUND',
    failed = 'FAILED',
    rejected = 'REJECT',
    new = 'NEW',
    any = 'ANY',
    CANCEL_OLD = 'CANCEL_OLD',
    TIMEOUT_3D = '3D',
    REFUND_IN_PROCESSING_QR = 'REFUND_IN_PROCESSING_QR',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS'
}

export enum PaymentType {
    credit = 'Credit',
    pan = 'PAN',
    cardId = 'cardId'
}

export enum CreditType {
    loan = 'LOAN',
    installment = 'INSTALLMENT'
}
interface MetaData {
    ARN: string,
    AuthCode: string,
    CardBin: string,
    TransAmount: number,
    ChargebackDate: string,
    ChargebackReasonCode: string,
    RRN: string,
    TransDate: string,
    TransCurrency: string
}
export interface Chargeback {
    shared_secret: string,
    invoice_id: string,
    operation: string,
    operation_id: string,
    status: string,
    amount: number,
    metadata: MetaData
}
export interface EpayPayment {
    accountId: string // идентификатор клиента магазина
    orgAmount: number
    amount: number // сумма транзакции
    amountBonus: number // сумма в бонусах
    authDate: Date // дата авторизации
    cardMask: string // маска карты
    cardType?: string // тип карты
    chargeDate: Date // дата подтверждения
    client: string // клиент
    createdDate: Date // дата создания
    currency: string // валюта транзакции
    data: any // дополнительные поля
    description: string // описание платежа
    fee: number // удержанная комиссия
    id: string
    installment_id: string // период рассрочки
    invoiceId: string // номер заказа магазина (orderid)
    ipCity: string // город
    ipCountry: string // страна
    ipDistrict: string // район
    ipLatitude: number // широта
    ipLongitude: number // долгота
    ipRegion: string // регион
    issuer: string // банк эмитент
    issuerBankCountry: string // страна банка эмитента
    language: string // язык
    merchant: string // мерчант
    payerEmail: string // почта клиента
    payerIp: string // ip клиента
    payerName: string // имя на карте
    payerPhone: string // номер телефона клиента
    postLink: string // ссылка отправки данных по транзакции
    postLinkStatus: boolean // был ли postlink успешным
    reference: string // ответ от карточной системы
    payoutAmount: number // сумма возмещения
    payoutDate: Date // дата возмещения
    secure3D: boolean // транзакция с 3D
    shop: string // магазин
    shopIsCreditCancelWithTransfer: boolean // признак частичного возврата
    status: PaymentStatus // статус
    template: string // имя шаблона платежной страницы
    resultCode: string // Код ошибки если оплата не прошла
    resultMessage: string // Сообщение ошибки если оплата не прошла
    transactionTypes?: string[]
    qr?: boolean // транзакция с QR
    paymentType?: string // Метод платежа pan | halykId | applePay | samsungPay
    approvalCode?: string // код авторизации
    invoiceIdAlt?: string // альтернативный номер заказа
    purpose?: string // назначение(комментарий от пользователя)
    user?: string // пользователь(мерчант)
    user_ip?: string // IP-пользователя
    creditType?: string // type of installment or loan
    terminalId: string // ID терминала
    chargeback: Chargeback // Chargeback
    qrReference: string
}

export interface EpaySummary {
    currency: string
    byStatus: {
        authorized: {
            count: number
            amount: number
        }
        charged: {
            count: number
            amount: number
        }
        cancelled: {
            count: number
            amount: number
        }
        refunded: {
            count: number
            amount: number
        }
    }
    byPaymentSystem: {
        visa: {
            count: number
            amount: number
        }
        masterCard: {
            count: number
            amount: number
        }
        amex: {
            count: number
            amount: number
        }
        other: {
            count: number
            amount: number
        }
    }
    timeline: TimelineItem[]
}

export interface TimelineItem {
    time: Date
    amount: number
    count: number
}