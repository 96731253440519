import { observer } from 'mobx-react'
import { Component } from 'react'
import React from 'react'
import { Button, IconButton, message, Spin } from '~/components'

import { action, observable, when } from 'mobx'
import { AlertLanguages, PaymentLink } from '~/api/payment-links'
import { CloseModalIcon } from '~/components/icon'

import ConfirmationPage from './ConfirmationPage'
import { ModalContainerContext } from '~/components/modal/modal-context'
import layoutStyles from '~/layouts/main/styles.css'
import globalRes from '~/res'
import BrowserStore from '~/services/browser/browser-state'
import res from './res'
import styles from './styles.css'

import config from '~/config'
import CreateInvoiceForm from './CreateInvoiceForm/'

export interface MerchantShop {
    id: string
    name: string
    contractNumber: string
}

export interface GeneratePaymentLinkBody {
    shop_id: string
    amount: number
    account_id: string
    invoice_id: string
    language: AlertLanguages
    currency: string
    description: string
    recipient_contact: string
    recipient_contact_sms: string
    notifier_contact: string
    notifier_contact_sms: string
    expire_period: string
    postLink?: string
    failurePostLink?: string
}

export interface GeneratePaymentLinkResponse {
    code: string
    message: string
}

interface GenerationFormProps {
    title?: React.ReactNode
    onClose: () => void
    onApply: (requestBody: GeneratePaymentLinkBody) => Promise<PaymentLink>
    modalContext?: ModalContainerContext
    paymentLinkUpdating?: boolean
}

interface Validator {
    shop_id: boolean
    amount: boolean
    account_id: boolean
    invoice_id: boolean
    language: boolean
    description: boolean
    recipient_contact: boolean
    recipient_contact_sms: boolean
    expire_period: boolean
}
interface GenerationFormState {
    paymentLink?: GeneratePaymentLinkBody
    createdPaymentLink: PaymentLink | null
    isGenerated: boolean
    hasError: boolean
    merchantShops: MerchantShop[]
    selectedStoreName: string
    shopsLoading: boolean
    validation: Validator
    amountFieldVisited: boolean
    accountIdFieldVisited: boolean
    invoiceIdFieldVisited: boolean
    descriptionFieldVisited: boolean
    validReceiverEmail: boolean | null
    receiverEmailVisited: boolean
    validReceiverPhone: boolean | null
    receiverPhoneVisited: boolean
    validNotifierEmail: boolean | null
    notifierEmailVisited: boolean
    validNotifierPhone: boolean | null
    notifierPhoneVisited: boolean
    notifierSMSSelected: boolean
    sendingRequest: boolean
    formValid: boolean
    errorMessage: string
}

@observer
export default class GenerationForm extends Component<
GenerationFormProps,
GenerationFormState
> {
    constructor(props: GenerationFormProps) {
        super(props)
        this.state = {
            paymentLink: {
                language: AlertLanguages.kazakh,
                invoice_id: '',
                amount: 100,
                shop_id: '',
                account_id: '',
                currency: 'KZT',
                description: '',
                expire_period: '',
                recipient_contact_sms: '',
                recipient_contact: '',
                notifier_contact_sms: '',
                notifier_contact: '',
                failurePostLink: '',
                postLink: ''
            },
            createdPaymentLink: null,
            selectedStoreName: '',
            merchantShops: [],
            isGenerated: false,
            hasError: false,
            shopsLoading: true,
            validation: {
                shop_id: false,
                amount: false,
                account_id: false,
                invoice_id: false,
                language: true,
                description: false,
                recipient_contact: false,
                recipient_contact_sms: false,
                expire_period: true
            },
            amountFieldVisited: false,
            accountIdFieldVisited: false,
            invoiceIdFieldVisited: false,
            descriptionFieldVisited: false,
            validReceiverEmail: false,
            receiverEmailVisited: false,
            validReceiverPhone: false,
            receiverPhoneVisited: false,
            validNotifierEmail: false,
            notifierEmailVisited: false,
            validNotifierPhone: false,
            notifierPhoneVisited: false,
            notifierSMSSelected: false,
            sendingRequest: false,
            formValid: false,
            errorMessage: ''
        }
    }

    @action
    public async componentDidMount() {
        try {
            if (this.props.modalContext) {
                const { title, onClose } = this.props
                const component = this
                this.props.modalContext.modalController.setTitle(
                    <div className={styles.mobileHeader}>
                        <div className={layoutStyles.title}>
                            {title || res().title}
                        </div>
                        <IconButton
                            className={`${styles.closeButton}`}
                            icon={CloseModalIcon}
                            onClick={onClose}
                            id="close-statement-config-icon"
                            tooltipTitle={globalRes().close}
                        />
                    </div>
                )
            }
        } catch (error) {
            message.error(error)
        }
    }

    public render() {
        const { title, onClose, paymentLinkUpdating } = this.props
        const { createdPaymentLink } = this.state

        return (
            <>
                <Spin spinning={paymentLinkUpdating}>
                    {!BrowserStore.isMobile && (
                        <div data-semantic-id="modal">
                            {' '}
                            <div>
                                <div className={layoutStyles.contentHeader}>
                                    <div className={layoutStyles.title}>
                                        {title || res().title}
                                    </div>
                                    <IconButton
                                        className={`${layoutStyles.closeButton} ${styles.closeButton}`}
                                        icon={CloseModalIcon}
                                        onClick={onClose}
                                        id="close-statement-config-icon"
                                        tooltipTitle={globalRes().close}
                                    />
                                </div>
                            </div>
                            <div className={styles.contentMain}>
                                {this.state.isGenerated ? (
                                    <ConfirmationPage
                                        createdPaymentLink={createdPaymentLink}
                                    />
                                ) : (
                                    <CreateInvoiceForm
                                        onApply={this.props.onApply}
                                        onClose={onClose}
                                        showConfirmation={this.showConfirmation}
                                    />
                                )}
                            </div>
                        </div>
                    )}

                    {BrowserStore.isMobile && (
                        <>
                            <div className={styles.mobileFormContainer}>
                                {!this.state.isGenerated && (
                                    <CreateInvoiceForm
                                        showConfirmation={this.showConfirmation}
                                        onApply={this.props.onApply}
                                        onClose={onClose}
                                    />
                                )}
                                {this.state.isGenerated && (
                                    <ConfirmationPage
                                        createdPaymentLink={
                                            this.state.createdPaymentLink
                                        }
                                    />
                                )}
                            </div>
                        </>
                    )}
                </Spin>
            </>
        )
    }

    @action.bound
    public async showConfirmation(newPaymentLink: PaymentLink) {
        this.setState({
            isGenerated: true,
            createdPaymentLink: newPaymentLink
        })
    }

    @action.bound
    private setCanvasRef(ref) {
        this.canvasRef = ref
    }

    @observable
    private canvasRef: HTMLCanvasElement
}
