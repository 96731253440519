import res from './res'

import { CreditType, PaymentStatus } from '~/api'

res.add('kk', {
    title: 'ePay төлемдер',
    ranges: {
        today: 'бүгін',
        yesterday: 'кеше',
        thisWeek: 'ағымдағы апта',
        priorWeek: 'өткен апта',
        thisMonth: 'ағымдағы ай',
        priorMonth: 'өткен ай',
        thisQuarter: 'ағымдағы тоқсан',
        customPeriod: 'көрсетілген кезең'
    },
    anyPaymentStatus: 'Кез келген',
    paymentStatuses: {
        [PaymentStatus.auth]: 'Күтеді',
        [PaymentStatus.charged]: 'Өткізілді',
        [PaymentStatus.cancelled]: 'Жойылды',
        [PaymentStatus.rejected]: 'Жойылды',
        [PaymentStatus.refunded]: 'Қайтарылды',
        [PaymentStatus.failed]: 'Сәтсіз',
        [PaymentStatus.new]: 'Жаңа',
        [PaymentStatus.any]: 'Кез келген',
        [PaymentStatus.CANCEL_OLD]: 'Мерзімі бойынша жойылды',
        [PaymentStatus.TIMEOUT_3D]: '3DS Қайтарылды',
        [PaymentStatus.REFUND_IN_PROCESSING_QR]: 'Орындалуда',
        [PaymentStatus.PENDING]: 'Күтүде',
        [PaymentStatus.SUCCESS]: 'Сәтті'
    },
    creditTypes: {
        [CreditType.loan]: 'Кредит',
        [CreditType.installment]: 'Рассрочка'
    },
    sortBy: 'Бойынша сұрыптау',
    filtersPlaceholder: 'Төлем атрибуттары бойынша сүзгі',
    dataFields: {
        full: {
            accountId: 'Клиент сәйкестендіргіші',
            amount: 'Төлем сомасы',
            amountBonus: 'Go Сомасы!бонустар',
            authDate: 'Авторизация күні',
            cardMask: 'Карта маскасы',
            cardType: 'Карта түрі',
            chargeDate: 'Растау күні',
            client: 'Клиент',
            createdDate: 'Құрылған күні',
            currency: 'Валюта',
            data: 'Қосымша жолдар',
            description: 'Бөлшектер төлем',
            fee: 'Ұсталған комиссия',
            installment_id: 'Бөліп төлеу мерзімі',
            invoiceId: 'Тапсырыс нөмірі',
            ipCity: 'Қаласы',
            ipCountry: 'Ел',
            ipDistrict: 'Ауданы',
            ipLatitude: 'Ені',
            ipLongitude: 'Ұзындығы',
            ipRegion: 'Аймақ',
            issuer: 'Банк эмитент',
            issuerBankCountry: 'Эмитент банкінің елі',
            language: 'Тілі',
            merchant: 'Мерчантқа сілтеме',
            payerEmail: 'Клиенттің Email',
            payerIp: 'Клиенттің IP',
            payerName: 'Карта иесінің аты',
            payerPhone: 'Клиенттің телефон нөмірі',
            postLink: 'Қайта бағыттау сілтемесі',
            postLinkStatus: 'Постлинк',
            reference: 'Карта жүйесінің жауабы',
            payoutAmount: 'Қайтару сомасы',
            payoutDate: 'Қайтару күні',
            secure3D: '3D Транзакция',
            shop: 'Дүкен атауы',
            status: 'Төлем мәртебесі',
            resultMessage: 'Нәтижесі',
            orgAmount: 'Бастапқы сомасы',
            template: '?',
            paymentType: 'Төлем әдісі',
            approvalCode: 'Авторизация коды',
            invoiceIdAlt: 'Балама тапсырыс нөмірі',
            user: 'Пайдаланушы',
            user_ip: 'Пайдаланушы IP',
            purpose: 'Түсінік',
            creditType: 'Несие түрі',
            qrReference: 'QR Reference'
        },
        short: {
            accountId: 'Клиент сәйкестендіргіші',
            amount: 'Төлем сомасы',
            amountBonus: 'Go Сомасы!бонустар',
            authDate: 'Авторизация күні',
            cardMask: 'Карта маскасы',
            cardType: 'Карта түрі',
            chargeDate: 'Растау күні',
            client: 'Клиент',
            createdDate: 'Құрылған күні',
            currency: 'Валюта',
            data: 'Қосымша жолдар',
            description: 'Бөлшектер төлем',
            fee: 'Ұсталған комиссия',
            installment_id: 'Бөліп төлеу мерзімі',
            invoiceId: 'Тапсырыс нөмірі',
            ipCity: 'Қаласы',
            ipCountry: 'Ел',
            ipDistrict: 'Ауданы',
            ipLatitude: 'Ені',
            ipLongitude: 'Ұзындығы',
            ipRegion: 'Аймақ',
            issuer: 'Банк эмитент',
            issuerBankCountry: 'Эмитент банкінің елі',
            language: 'Тілі',
            merchant: 'Мерчантқа сілтеме',
            payerEmail: 'Email',
            payerIp: 'Клиенттің IP',
            payerName: 'Карта иесінің аты',
            payerPhone: 'Телефон',
            postLink: 'Қайта бағыттау сілтемесі',
            postLinkStatus: 'Постлинк',
            reference: 'РРН',
            payoutAmount: 'Қайтару сомасы',
            payoutDate: 'Қайтару күні',
            secure3D: '3D Транзакция',
            shop: 'Дүкен',
            status: 'Статус',
            resultMessage: 'Нәтижесі',
            orgAmount: 'Бастапқы сомасы',
            template: 'Шаблон платежной страницы',
            paymentType: 'Төлем әдісі',
            approvalCode: 'Авторизация коды',
            invoiceIdAlt: 'Балама тапсырыс нөмірі',
            user: 'Пайдаланушы',
            user_ip: 'Пайдаланушы IP',
            purpose: 'Түсінік',
            creditType: 'Несие түрі',
            qrReference: 'QR Reference'
        },
        groups: {
            location: 'Орналасқан жері',
            paymentPage: 'Төлем беті'
        },
        geolocation: 'Геолокация'
    },
    sortOptions: {
        date: 'Күні бойынша',
        postLink: 'Постлинк',
        status: 'Мәртебесі бойынша',
        type: 'Типі бойынша',
        asc: {
            date: 'басында ескілері',
            postLink: 'басындағы сәттілер',
            status: 'басында күтеді'
        },
        desc: {
            date: 'басында жаңалары',
            postLink: 'басындағы сәтсіздері',
            status: 'алдымен қайтарылғандар'
        }
    },
    installments: {
        full: {
            '3m': '3 ай',
            '6m': '6 ай',
            '9m': '9 ай',
            '12m': '12 ай'
        },
        short: {
            '3m': '3m',
            '6m': '6m',
            '9m': '9m',
            '12m': '12m'
        }
    },
    secure3DValues: {
        true: 'Иә',
        false: 'Жоқ'
    },
    partialSum: {
        isRequired: 'Толтыруға міндетті сома',
        constraints: 'Сома төлем сомасынан аспайтын оң сан болуы тиіс'
    },
    operations: {
        fullCharge: {
            title: 'Толық есептен шығару',
            itemError: paymentReference =>
                `#${paymentReference} Төлем бойынша толық есептен шығару қатесі `
        },
        partialCharge: {
            title: 'Ішінара есептен шығару',
            itemError: paymentReference =>
                `#${paymentReference} Төлем бойынша ішінара есептен шығару қатесі `
        },
        cancel: {
            title: 'Болдырмау',
            itemError: paymentReference =>
                `#${paymentReference} Төлемді болдырмау қатесі`
        },
        fullRefund: {
            title: 'Толық қайтару',
            itemError: paymentReference =>
                `#${paymentReference} Төлемді толық қайтару қатесі`
        },
        partialRefund: {
            title: 'Ішінара қайтару',
            itemError: paymentReference =>
                `#${paymentReference} Төлемді ішінара қайтару қатесі `
        }
    },
    postLinkStatus: {
        all: 'Барлығы',
        ok: 'Сәтті',
        fail: 'Қате'
    },
    collapseSummary: range =>
        `${range instanceof Function ? range() : range || 'период'
        } кезең үшін статистика`,
    errors: {
        summaryLoadError: 'Статистиканы жүктеу қатесі',
        paymentsLoadError: 'Төлемдерді жүктеу қатесі'
    },
    chargeback: {
        shared_secret: 'Shared secret',
        invoice_id: 'Invoice ID',
        operation: 'Операция',
        operation_id: 'Операция идентификаторы',
        status: 'Статус',
        amount: 'Сомасы',
        ARN: 'ARN',
        authCode: 'Аутентификация коды',
        cardBin: 'БИН',
        transAmount: 'Трансфер сомасы',
        chargebackDate: 'Chargeback күні',
        chargebackReasonCode: 'Себеп коды',
        RRN: 'RRN',
        transDate: 'Трансфер күні',
        transCurrency: 'Транзакция валютасы',
        yes:  'Иә',
        no: 'Жоқ'
    },
    errorCodes: {
        454: 'Операция сәтсіз аяқталды, картадағы соманың оқшауланғанын тексеріңіз және кейінірек әрекетті қайталаңыз.',
        455: '3DSecure/Securecode ровері қол жетімді емес немесе карта нөмірі дұрыс енгізілмеген. Басқа браузерді/құрылғыны пайдаланып көріңіз. Егер қате қайталанса, кодты қайта белгілеңіз.',
        456: 'Бұл карта бойынша төлем жасау мүмкін емес.',
        457: 'Картаның жарамдылық мерзімі дұрыс енгізілмеген.',
        458: 'Сервер жауап бермейді. Кейінірек көріңіз.',
        459: 'Сервер жауап бермейді. Кейінірек көріңіз.',
        460: 'Қате орын алды, картада сома оқшауланған болуы мүмкін, Қолдау қызметіне хабарласыңыз.',
        461: 'Жүйелік қате, егер қате қайталанса, кейінірек транзакция жасап көріңіз қолдау қызметіне хабарласыңыз.',
        462: 'Транзакцияны сіздің банк қабылдамады. Бас тарту себебін нақтылау үшін картаңыздың артқы жағында көрсетілген байланыстар бойынша өтініш жасау қажет.',
        463: 'Транзакцияны сіздің банк қабылдамады. Бас тарту себебін нақтылау үшін картаңыздың артқы жағында көрсетілген байланыстар бойынша өтініш жасау қажет.',
        464: 'Жарамсыз саудагер.',
        465: 'Карта бұғатталған.',
        466: 'Транзакцияны сіздің банк қабылдамады. Бас тарту себебін нақтылау үшін картаңыздың артқы жағында көрсетілген байланыстар бойынша өтініш жасау қажет.',
        467: 'Карта бұғатталған.',
        468: 'Қосымша сәйкестендіру қажет.',
        469: 'Жарамсыз транзакция, енгізілген деректерді қайта тексеріңіз.',
        470: 'Транзакция сомасы нөлге тең, қайталап көріңіз.',
        471: 'Жарамсыз карточка нөмірі, карта нөмірін енгізудің дұрыстығына көз жеткізіп, қайталап көріңіз.',
        472: 'Жарамсыз карточка нөмірі, карта нөмірін енгізудің дұрыстығына көз жеткізіп, қайталап көріңіз.',
        473: 'DSecure / Securecode енгізілген немесе дұрыс енгізілмеген. Енгізудің дұрыстығына көз жеткізіңіз немесе құпия сөзді қайта белгілеңіз. Егер қате қайталанса, қолдау қызметіне хабарласыңыз.',
        475: 'Транзакция сәтсіз аяқталды. Қайта қайталаңыз.',
        476: 'Транзакцияны қайта жүргізу кем дегенде 30 минуттан кейін қолжетімді болады.',
        477: 'Қате, басқа картаны пайдаланыңыз. Ол болмаған жағдайда Қолдау қызметіне хабарласыңыз help.epay@halykbank.kz',
        478: 'Картаның мерзімі өтіп кеткен.',
        479: 'Карта бұғатталған.',
        480: 'Эмитент-банкке өтініш жасау.',
        481: 'Карта жарамсыз. Жаңа карта шығару үшін банкке хабарласыңыз.',
        482: 'Карта жарамсыз. Жаңа карта шығару үшін банкке хабарласыңыз.',
        483: 'Карта мәртебесі - ұрланған. Жаңа картаны шығару үшін банкке хабарласыңыз.',
        484: 'Картада қаражат жеткіліксіз.',
        485: 'Картаның мерзімі аяқталған.',
        486: 'Транзакция қабылданбады. Картада интернет желісінде сатып алу мүмкіндігіне тыйым салынған немесе карта деректері дұрыс енгізілмеген.',
        487: 'Транзакция қабылданбады, қолдау қызметіне хабарласыңыз.',
        488: 'Сома рұқсат етілген шектен асады.',
        489: 'Карта бұғатталған.',
        490: 'Сіздің картаңыз бойынша транзакция жасауға тыйым салынған, қосымша ақпарат алу үшін картаңыздың артқы жағында көрсетілген байланыстарға хабарласыңыз.',
        491: 'Төлем жиілігі лимитінен асып кетті.',
        492: 'ДСН (ПИН)-кодтың дұрыс енгізілмеуіне байланысты карта оқшауланған, қосымша ақпарат алу үшін картаңыздың артқы жағында көрсетілген байланыстарға хабарласыңыз.',
        493: 'Картаны шығарған банк қолжетімді емес, төлемді кейінірек қайталап көріңіз.',
        494: 'Транзакция сәтті болмады-картаны шығарған банк, кейінірек транзакция жасап көріңіз.',
        495: 'Транзакцияға тыйым салынған, басқа картаны пайдаланыңыз.',
        496: '3DSecure SecureCode absent. Егер ККБ картасы болса-3DSecure SecureCode орнатылмаған. Басқа жағдайларда мәселе Эмитент жағынан.',
        497: 'Сервер жауап бермейді. Кейінірек көріңіз.',
        498: 'Бонустармен төлеу мүмкін емес. Кейінірек көріңіз.',
        499: '3DSecure/Securecode қате енгізілген немесе енгізілмеген.',
        500: '3DSecure/Securecode тексеру қол жетімді емес немесе сіз карта нөмірін қате енгіздіңіз. Басқа браузерді/құрылғыны пайдаланып көріңіз. Егер қате қайталанса, құпия сөзді қалпына келтіріңіз.',
        501: 'Картаға қызмет көрсету қатесі. Картаның дұрыс енгізілгенін тексеріңіз. Егер қате қайталанса, қолдау қызметіне хабарласыңыз.',
        502: 'Транзакцияның бұл түрі міндетті түрде 3DSecure/Securecode құпия сөзін енгізуді талап етеді.',
        503: 'Бұл операция сізден 3D Secure құпия сөзін пайдалануды талап етеді.',
        521: 'Транзакцияны сіздің банк қабылдамады. Бас тарту себебін нақтылау үшін картаңыздың артқы жағында көрсетілген байланыстар бойынша өтініш жасау қажет.',
        522: 'Жазба табылмады, картаны тексеріңіз.',
        1636: 'Карта иесін анықтау мүмкін болмады.',
        1654: 'OTP кодын тексеру мүмкін болмады.',
        2092: 'Транзакция сәтсіз аяқталды.',
        2678: 'Терминалда тәуліктік лимит асып кетті.',
        2740: 'Cannot approve at this time (restricted error code)',
        2872: 'Do not reattempt (restricted error code)',
    }
})
