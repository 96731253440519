import res from './res'

res.add('kk', {
    dataFields: {
        titles: {
            createdDate: 'Құрылған күні',
            endDate: 'Аяқталу күні',
            order: 'Тапсырыс',
            sum: 'Сомасы',
            language: 'Хабарлау тілі',
            installment: 'Бөліп төлеу кезеңі',
            status: 'Мәртебесі',
            merchantId: 'Мерчант ID',
            description: 'Сипаттама',
            emailToSend: 'Жіберу үшін Email',
            phoneToSend: 'Жіберуге арналған телефон',
            notificationEmail: 'Хабарландыру үшін Email',
            notificationPhone: 'Хабарландыру үшін телефон'
        }
    },
    sortOptions: {
        createdDate: 'Құрылған күні',
        expirationDate: 'Аяқталу күні',
        order: 'Тапсырыс',
        asc: {
            createdDate: 'басында бұрынғы',
            expirationDate: 'өсу бойынша',
            order: 'өсу бойынша'
        },
        desc: {
            createdDate: 'басында жаңа',
            expirationDate: 'кему бойынша',
            order: 'кему бойынша'
        }
    },
    language: 'Тіл',
    languages: {
        eng: 'Ағылшын',
        rus: 'Орыс',
        kaz: 'Қазақша'
    },
    postLinkStatuses: {
        ACTIVE: 'Белсенді',
        ANNULLED: 'Жойылды',
        EXPIRED: 'Мерзімі өткен',
        CHARGED: 'Есептен шығарылған'
    },
    tooltips: {
        copyLink: 'Сілтемені көшіру',
        cancelLink: 'Сілтемені жою'
    },
    sortBy: 'Бойынша сұрыптау',
    installmentLabel: (val: string) => {
        const value = parseInt(val, null)

        if (!value || value === 0) return 'Бөліп төлеу жоқ'

        return `${value} ай`
    },
    inactiveLinkWarningTitle: 'Назар аударыңыз',
    inactiveLinkWarning: 'Бұл сілтеме аяқталды, жойылды немесе жойылды'
})
