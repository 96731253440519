import React from 'react'
import res from './res'

res.add('en', {
    title: 'Generating a payment link',
    dataToSendLink: 'Data to send link',
    generalData: 'General data',
    chooseStoreLabel: 'Choose the store',
    invoicePlaceholder: 'Input your invoice id',
    accountIdPlaceholder: 'Input your client\'s id',
    paymentNotificationData: 'Payment notification data',
    fieldErrorMessage: 'Please check this field',
    generateButton: 'Generate',
    applyButton: 'Apply',
    cancelButton: 'Cancel',
    closeButton: 'Close',
    months: 'months',
    notRequired: 'not required',
    withoutInstallment: 'Without installment',
    installmentTooltip:
        'You can sell your goods in installments. To do this, you need to activate the service "Installment"',
    invalidAccountId:
        'Client ID must be between 8 and 30 characters, cannot contain characters, and cannot be empty',
    invalidShop: 'Shop must be selected',
    invalidAmount:
        'Amount is required, and must be at least 100 KZT in 100.00 format',
    descriptionRequired: 'Description must not be empty',
    descriptionMaxLength: 'Description must not exceed the character limit',
    invalidEmail: 'Must be a valid email',
    invalidOrderId:
        'Invoice ID must be between 8 and 16 characters and contain only numeric characters.',
    invalidPhone: 'Must be a valid phone number',
    formItemTitles: {
        orderNumber: 'Order number',
        orderSum: 'Order amount',
        accountId: 'Client\'s ID number',
        language: 'Alert language',
        recieverEmail: 'Receiver\'s email',
        recieverPhone: 'SMS Receiver\'s phone number',
        senderEmail: 'Email for notification',
        senderPhone: 'Phone number for notification',
        email: 'Email',
        phone: 'Phone number',
        description: 'Order description',
        expirePeriod: 'Link validity period (days)'
    },
    confirmationTitle: (orderNumber: string) => (
        <div>
            Payment link for order {orderNumber} <br /> is generated and sent
        </div>
    ),
    clipBoardMessage: 'Link copied to clipboard',
    sumError: 'Enter amount in this format: 100',
    duplicateOrderIdError: 'Order ID already exists. Please input other value.',
    serverError: 'Could not get data from the server',
    noShopsError: 'Could not get active shops. Please contact support'
})
