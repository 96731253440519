import 'moment/locale/ru'

import antd from 'antd/lib/locale-provider/ru_RU'

import res from './res'

res.add('kk', {
    unknownError: 'Біз қазір сіздің сұрауыңызды өңдей алмаймыз. Оны кейінірек қайталаңыз',
    errorCodes: {
        75: 'Мұндай email-пайдаланушы тіркелген',
        506: 'Қайтару операциясын транзакция жасалғаннан кейін келесі күні өткізуге болады',
        3141: 'Келісімшарт бойынша қаражаттың болмауына байланысты қайтару мүмкін емес, келісімшартты толтырыңыз немесе жаңа төлемдердің өтелуін күтіңіз.'
    },
    permissionError: 'Сіз бұл әрекетке қол жеткізе алмайсыз.  Әкімшіге хабарласыңыз',
    required: 'Міндетті өріс',
    invalidEmail: 'Еmail-дің форматы сәйкес келмейді',
    invalidNumber: 'Енгізілген мән сан емес',
    success: 'Орындалды',
    info: 'Ақпарат',
    warn: 'Ескерту',
    error: 'Қате',
    valueFrom: 'бастап',
    valueTo: 'дейін',
    techinalInfo: 'Техникалық ақпарат',
    errorCode: 'Қате коды',
    close: 'Жабу',
    paymentsSystems: {
        visa: 'Visa',
        masterCard: 'Mastercard',
        amexCard: 'American Express',
        other: 'Басқа карталар'
    },
    longNumberDigits: {
        thousand: 'k',
        million: 'M',
        billion: 'G',
        trillion: 'T',
        quadrillion: 'P',
        quintillion: 'E'
    },
    antd,
    failedToFetch: 'Желі байланысындағы ақаулық немесе сұратым брандмауэр немесе басқа қауіпсіздік шарасы арқылы бұғатталған'
})
